<template>
  <div class="d-none d-lg-flex align-items-end px-0 banner w-100 h-100">
    <div
      v-lazy:background-image="leftBg"
      class="w-100 d-lg-flex justify-content-center left-image p-0"
    >
      <div class="content-left-image">
        <h1 class="title-content">
          Increase Traffic and Sales
        </h1>
        <h3 class="title-detail-content">
          <b-img
            class="icon-check"
            fluid
            alt="icon check"
            src="@/assets/images/pages/auth/ic-check-white.svg"
          />
          Whitelist TikTok Ad Account
        </h3>
        <p class="sub-content">
          Easy to launch your ads campaign with an unlimited budget
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BImg,
  },

  mixins: [envMixin],

  data() {
    return {}
  },

  computed: {
    leftBg() {
      // eslint-disable-next-line global-require
      return process.env.VUE_APP_LOGIN_SLIDE_3 || require('@/assets/images/pages/auth/img-slide-3.jpeg')
    },

  },
}
</script>
<style lang="scss" scoped>
.banner {
  .left-image {
    max-height: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .swiper-container {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;

      .bg-banner-login {
        height: 100%;
        max-width: 200%;
        right: 0;
      }
    }
  }
}

.margin-icon {
  margin-right: 14px;
}

.margin-content {
  margin-top: 32px;
}

.text-banner {
  margin: 73px 20% 73px 15%;
}

.img-bg-left {
  margin-top: 0.5rem;
  width: 75%;

  @media (min-width:1540px) {
    margin-top: 4rem;
    width: 100%;
  }
}
.content-left-image{
  padding: 38px 50px 38px 174px;
  color: #fff;

  @media (min-width: 768px) and (max-width: 1292px) {
    padding: 38px 50px 38px 50px;
  }
  @media (min-width: 1293px) and (max-width: 1500px) {
    padding: 38px 50px 38px 142px;
  }

  .title-content{
    font-size: 32px;
    color: #fff;
  }

  .title-detail-content{
    color: #fff;
    font-size: 20px;

    .icon-check{
      padding: 0 !important;
    }
  }

  .sub-content{
    margin-left: 27px;
  }
}

.bottom-banner-content {
  color: #fff;
  position: absolute;
  bottom: 20px;
  right: 30px;
  text-decoration-line: underline;
}
</style>
